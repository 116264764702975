@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  }
}

$baseFont: "Metropolis", Helvetica, Arial, sans-serif;
$baseEmojiFont: "Metropolis", Helvetica, Arial, apple color emoji,
  segoe ui emoji, noto color emoji, android emoji, emojisymbols,
  emojione mozilla, twemoji mozilla, segoe ui symbol, sans-serif;
$iconFont: "icomoon";
$primaryColor: #d4272b;
$toggleBg: #f1f5f5;
$brandColor: #d4272b;
$brandColorDarken: #b80015;
$lightRedColor: #f3ebec;
$fieldErrorBorderColor: #e32222;
$enabledSwitchColor: #368232;
$disabledSwitchColor: $primaryColor;

@import "../../mixins.scss";

.BriefInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: solid 1px #dce7e7;
  padding: 0 20px;

  @include for-size(tablet-landscape-up) {
    padding: 0;
  }

  h1,
  p {
    color: #111;
    text-align: center;
  }

  h1 {
    font: bold 24px $baseFont;
    margin: 60px 0 0;

    @include for-size(phone-only) {
      margin-top: 30px;
      line-height: 30px;
    }

    @include for-size(tablet-landscape-up) {
      br {
        display: none;
      }

      font-size: 30px;
    }
  }

  p {
    font: 15px/25px $baseFont;
    margin: 20px 0 40px;

    @include for-size(tablet-landscape-up) {
      font-size: 17px;
    }

    > b {
      color: $brandColor;
    }
  }

  &__stats {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    > div {
      border: solid 4px #dce7e7;
      border-radius: 50%;
      text-align: center;
      width: 150px;
      height: 150px;

      > h2 {
        font: 40px/1 $baseFont;
        margin: 34px 0 0;
      }

      > p {
        font: 14px/130% $baseFont;
        margin: 5px 0 0;
      }

      &:first-child {
        border-color: $brandColor;
        margin-right: 20px;

        @include for-size(tablet-landscape-up) {
          margin-right: 40px;
        }

        > h2 {
          color: $brandColor;
        }
      }
    }
  }

  &__first {
    font: 15px/23px $baseFont;
    margin-bottom: 30px;

    > strong {
      color: $brandColor;
      display: block;
      margin-top: 5px;
      font: bold 20px/1 $baseFont;
    }
  }

  &__info {
    border: solid 1px #dce7e7;
    border-radius: 10px;
    box-shadow: 0px 5px 10px -4px rgba(0, 0, 0, 0.1);
    padding: 0 40px;
    margin-bottom: 50px;
    max-width: 590px;
    width: 100%;
    font: 15px/20px $baseFont;

    &.expanded {
      padding-top: 30px;

      @include for-size(phone-only) {
        padding: 10px 10px 0;
      }

      > div {
        display: relative;
        height: auto;
        font-size: inherit;
        border-bottom: solid 1px #f1f5f5;
        padding: 7px 0;

        &:last-of-type {
          margin-bottom: 18px;
        }
      }

      > button {
        border-radius: 0 0 10px 10px;

        @include for-size(phone-only) {
          width: calc(100% + 20px);
          margin-left: -10px;
        }
      }
    }

    > div {
      display: absolute;
      height: 0;
      font-size: 0;

      > span.multiline {
        display: block;
        margin-top: 11px;
      }

      > span:not(.multiline) {
        float: right;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }

    > button {
      background-color: #f1f5f5;
      color: #111;
      font: bold 15px/52px $baseFont;
      width: calc(100% + 80px);
      margin-left: -40px;
      border-radius: 10px;

      &:hover {
        font-weight: bold;
      }

      .icon-arrow-right {
        transform: rotate(90deg);
        display: inline-block;
        margin-left: 10px;
        font-size: 12px;
        margin-top: 1px;

        &.iconHide {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

@import "../../mixins.scss";

.footer-section {
  max-width: 610px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 0 35px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    padding: 0 15px;
    list-style: none;

    a {
      text-decoration: none;
    }

    &.top-links {
      margin-bottom: 30px;

      a {
        color: #111;
        font-size: 15px;
        font-weight: 700;
      }
    }

    &.tell-me-more {
      justify-content: center;

      li {
        font-size: 12px;
        margin: 0 20px;
        text-align: center;
      }

      span,
      a {
        color: #8e8e8e;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
      }

      .link {
        color: #d4272b;
        font-weight: bold;
      }
    }
  }

  @include for-size(phone-only) {
    padding-top: 10px;
    padding-bottom: 20px;

    ul.top-links {
      border-bottom: 1px solid #dce7e7;
      flex-direction: column;
      margin-bottom: 30px;

      li {
        margin-bottom: 30px;
      }
    }

    ul.tell-me-more {
      display: block;
      text-align: center;

      li {
        margin: 0 10px 10px;
        display: inline-block;

        &:first-child {
          display: block;
          margin: 0 0 10px;
        }
      }
    }
  }
}

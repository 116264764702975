@import "../../mixins.scss";

.date-update {
  font-size: 15px;
  text-align: center;
  margin-bottom: 25px;

  span {
    font-weight: bold;
  }

  @include for-size(phone-only) {
    margin-bottom: 20px;

    span {
      display: block;
      margin-top: 10px;
    }
  }
}

.wrp-talents {
  display: flex;
  align-items: center;
  flex-flow: row wrap;

  @include for-size(phone-only) {
    justify-content: center;
  }

  .wrp-img {
    display: block;
    height: 180px;
    transition: height 300ms ease-out;
    overflow: hidden;
  }

  img {
    display: block;
    overflow: hidden;
  }

  .p-card {
    position: relative;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 340px;
    height: 425px;
    margin: 15px;

    .p-card-body {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #ffffff;
      padding: 0;

      .p-card-content {
        padding-bottom: 0;
      }
    }

    &.without-one-tab .p-card-body {
      padding-bottom: 43px;
    }

    .p-card-title {
      font-size: 18px;
      margin: 15px 20px;

      p {
        margin: 0;

        &:last-child {
          font-size: 13px;
          font-weight: normal;
          color: #848484;
        }
      }
    }

    .p-card-subtitle {
      margin: 3px 20px 0 20px;
      font-size: 13px;
      font-weight: 400;

      p {
        margin: 0;

        &:last-child {
          margin-top: 5px;
        }
      }
    }
  }

  .card-content {
    line-height: 1.3;

    .accordionItem {
      text-align: left;

      a {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        color: #d4272b;
        text-decoration: none;
        padding: 15px 20px;
        font-weight: 700;

        &:hover {
          background: #dbdbdb;
          border-color: #dbdbdb;
          color: #333333;
        }
      }
    }

    .addTalent {
      text-align: center;
      padding: 10px 0 0 0;

      .addTalentButton {
        background-color: #fff;
        color: $brandColor;
        border-color: $brandColor;
        text-decoration: none;

        > span {
          margin-left: 8px;
        }

        &:focus,
        &:hover {
          box-shadow: 0 0 0 0.05rem $brandColor;
        }
      }
    }

    .p-accordion {
      .country,
      .notice-period {
        margin-bottom: 10px;
      }
    }
  }

  .p-accordion {
    .p-accordion-content {
      p {
        margin: 0;
      }

      .wrp-description {
        max-height: 108px;
        overflow: auto;

        a {
          text-decoration: none;
          color: $brandColor;
          display: block;
          padding: 3px 0 3px 0;

          &:first-child {
            margin-bottom: 6px;
          }

          span {
            font-size: 0.8em;
          }
        }
      }
    }
  }
}

@import "../../mixins.scss";

html,
body,
#root {
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Metropolis, "Helvetica Neue", sans-serif;
}

* {
  outline: none;
}

hr {
  border: solid 1px #dce7e7;
  border-bottom: 0;
  margin: 20px 0;
}

sub.error {
  color: $fieldErrorBorderColor !important;
  font-family: $baseFont !important;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.powered {
  margin: 0 auto;
  width: 200px;
}

.hidden {
  display: none;
}

.flex {
  display: flex;
}

.error-msg {
  color: #dd3b26;
  font: 13px/1.5 $baseFont;
  display: block;
  margin: 10px 0;
  text-align: center;
}

textarea {
  max-width: 100%;
  min-width: 100%;
}
input[type="tel"] {
  padding-left: 80px;
}

.help-icon {
  vertical-align: bottom;
  height: 16px;
  margin-left: 0.5em;
}

button {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  &:hover {
    opacity: 0.9;
    font-weight: 500;
  }
}
.mandatory::after {
  color: #dd3b26;
  content: "*";
  font-weight: 800;
  font-size: 18px;
  margin-left: 0.3em;
}

.select {
  > span {
    display: none !important;
  }
  > span + div {
    background-color: white !important;
    box-shadow: none !important;
    outline: none !important;
  }
  > div {
    background: #f5f5f5 !important;
    border-width: 2px !important;
    border-color: transparent;
    padding: 0px 10px !important;
    font: 15px/18px $baseFont !important;
    border-radius: 3px !important;
    &:hover {
      border-color: transparent;
    }
    &:last-child {
      padding: 0 !important;
    }
  }
}

.tooltip {
  background: #4b4b4b;
  border-radius: 3px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
  color: #fff;
  // display: none;
  opacity: 0;
  transition: opacity 0.3s ease, margin 0.3s ease;
  font: 13px $baseFont;
  left: 100%;
  margin-left: 30px;
  position: absolute;
  padding: 14px 20px;
  top: 50%;
  transform: translateY(-50%) scale(0);
  z-index: 10;
  line-height: 100%;
  min-width: 100px;
  &::after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-right-color: #4b4b4b;
    border-width: 5px;
    margin-top: -5px;
  }
}

.field {
  position: relative;
  button.eye {
    bottom: 2px;
    height: 60px;
    position: absolute;
    right: 2px;
    width: 60px;
    > i {
      font-size: 60px;
    }
  }
}

b.red-bordered {
  display: inline-block;
  font: 700 15px/23px $baseFont;
  margin: 30px 0;
  border: solid 1px $primaryColor;
  border-radius: 14px;
  font: 700 13px/20px $baseFont;
  padding: 20px 30px;
  &:first-child {
    margin-top: 0;
  }
}

.field > .red-bordered {
  margin-top: 30px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000 !important;
}

.p-inputswitch {
  .p-inputswitch-slider {
    background: $toggleBg !important;
    &:before {
      background: $disabledSwitchColor;
      box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.25);
      content: "\e916";
      color: #fff;
      font-family: "icomoon" !important;
      speak: none;
      font-size: 0.5em;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-align: center;
      text-transform: none;
      line-height: 20px;
      vertical-align: text-top;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  &.p-inputswitch-checked {
    .p-inputswitch-slider {
      background: $toggleBg !important;
      &:before {
        background: $enabledSwitchColor;
        content: "\e915";
      }
    }
  }
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis"),
    url("../../assets/fonts/metropolis.woff2") format("woff2"),
    url("../../assets/fonts/metropolis.woff") format("woff"),
    url("../../assets/fonts/metropolis.ttf") format("truetype"),
    url("../../assets/fonts/metropolis.eot"),
    url("../../assets/fonts/metropolis.eot#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/montserrat/montserrat.eot"); /* IE9 Compat Modes */
  src: local("Montserrat"),
    url("../../assets/fonts/montserrat/montserrat.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/montserrat/montserrat.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../assets/fonts/montserrat/montserrat.woff") format("woff"),
    /* Modern Browsers */ url("../../assets/fonts/montserrat/montserrat.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/montserrat/montserrat-700.eot"); /* IE9 Compat Modes */
  src: local("Montserrat"),
    url("../../assets/fonts/montserrat/montserrat-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../../assets/fonts/montserrat/montserrat-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../../assets/fonts/montserrat/montserrat-700.woff") format("woff"),
    /* Modern Browsers */
      url("../../assets/fonts/montserrat/montserrat-700.ttf") format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "icomoon";
  src: local("icomoon"),
    url("../../assets/fonts/icomoon.woff?prrcgn") format("woff"),
    url("../../assets/fonts/icomoon.ttf?prrcgn") format("truetype"),
    url("../../assets/fonts/icomoon.svg?prrcgn#icomoon") format("svg"),
    url("../../assets/fonts/icomoon.eot?prrcgn"),
    url("../../assets/fonts/icomoon.eot?prrcgn#iefix")
      format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: text-top;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shield:before {
  content: "\e918";
}
.icon-knob-check:before {
  content: "\e915";
  color: #fff;
}
.icon-knob-cross:before {
  content: "\e916";
  color: #fff;
}
.icon-disabled:before {
  content: "\e917";
  color: #c1d6d5;
}
.icon-arrow-right:before {
  content: "\e914";
  color: #d4272b;
}
.icon-lock:before {
  content: "\e90f";
}
.icon-tie:before {
  content: "\e910";
}
.icon-briefcase:before {
  content: "\e911";
}
.icon-user-outline:before {
  content: "\e912";
}
.icon-logout:before {
  content: "\e913";
}
.icon-tick:before {
  content: "\e90e";
}
.icon-error .path1:before {
  content: "\e90c";
  color: rgb(227, 34, 34);
}
.icon-error .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-msg-delivered:before {
  content: "\e904";
  color: #d4272b;
}
.icon-msg-read:before {
  content: "\e90b";
  color: #d4272b;
}
.icon-emoji:before {
  content: "\e903";
}
.icon-back:before {
  content: "\e900";
}
.icon-cancel:before {
  content: "\e901";
}
.icon-edit:before {
  content: "\e902";
}
.icon-eye:before {
  content: "\e905";
  color: #8e8e8e;
}
.icon-help:before {
  content: "\e906";
  color: #d40018;
}
.icon-list:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e908";
  color: #d40018;
}
.icon-report:before {
  content: "\e909";
}
.icon-user:before {
  content: "\e90a";
}

@import "../../mixins.scss";

.spotlight {
  h1 {
    color: #000;
  }

  .wrp-talents {
    .p-card {
      height: 442px;

      &.without-one-tab .p-card-body {
        padding-bottom: 15px;
      }
    }
  }

  > p {
    font: 17px/25px "Metropolis", "Helvetica Neue", sans-serif;
    color: #111;
    padding: 0 33px;
    text-align: center;
  }
}

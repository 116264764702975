@import "../../mixins.scss";

.SidePanel {
  align-items: flex-start;
  background: $brandColor;
  border-radius: 0 0 20px 20px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  height: 70px;
  justify-content: space-between;
  left: 0;
  padding: 15px;

  @include for-size(tablet-landscape-up) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    width: 100%;
    z-index: 1000;
  }

  a {
    margin: auto;
  }

  &__Logo {
    background-image: url("../../assets/genie-logo-inverted.svg");
    background-size: cover;
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;

    @include for-size(tablet-landscape-up) {
      margin-bottom: 25px;
    }
  }

  @include for-size(tablet-landscape-up) {
    background: $brandColor;
    border-radius: 0 20px 20px 0;
    bottom: 0;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.1);
    display: block;
    height: 100%;
    right: auto;
    width: 70px;
  }
}

@import "../../mixins.scss";

.MainView {
  background: url("../../assets/genie-mobile-chat-bg.svg") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  min-width: 375px;
  overflow: auto;

  @include for-size(tablet-landscape-up) {
    padding-top: 0;
    padding-left: 70px;
    background-image: url("../../assets/genie-desktop-chat-bg.svg");
  }
}

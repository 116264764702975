.p-accordion {
  .p-accordion-header {
    &:not(.p-disabled).p-highlight {
      .p-accordion-header-link {
        color: #d4272b;
        background: #fff;
        border-color: #fff;
      }
    }

    .p-accordion-header-link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      font-size: 12px;
      text-transform: uppercase;
      border: none;
      border-bottom: 1px solid #f3f3f3;
      color: #d4272b;
      background: #fff;
      border-radius: 0px;

      .p-accordion-toggle-icon {
        order: 1;
        margin-right: 0;
        font-size: 0.6rem;
      }
    }

    &:not(.p-disabled) {
      .p-accordion-header-link:focus {
        box-shadow: none;
      }

      &.p-highlight:hover .p-accordion-header-link {
        border-color: #fff;
        background: #fff;
        color: #d4272b;
      }
    }
  }

  .p-accordion-content {
    border: none;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0;
    padding-top: 0;
    padding: 0 20px 10px;
  }

  .p-accordion-tab {
    margin-bottom: 0px;
  }
}

body {
  .p-checkbox-label,
  .p-radiobutton-label {
    margin: 0 0 0 0.5em;
  }

  .p-component {
    font-family: Metropolis, Helvetica Neue, sans-serif;
    font-size: 14px;

    .p-card-title {
      font-family: Montserrat, sans-serif;
    }
  }

  .p-tabmenu {
    .p-tabmenu-nav {
      padding: 0;
      background: transparent;
      border-bottom: 1px solid #c8c8c8;
    }
  }
}

.p-progress-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

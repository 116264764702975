@import "../../mixins.scss";

.wrp-talent-section {
  padding: 40px 0;
  border-bottom: 1px solid #dce7e7;

  &.frame {
    border-bottom: 2px solid $primaryColor;
  }

  h1 {
    color: #c1d6d5;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin: 40px 0 40px;

    @include for-size(phone-only) {
      font-size: 23px;
      margin-bottom: 60px;
    }
  }

  &.spotlight h1 {
    margin-top: 0;
  }

  @include for-size(phone-only) {
    padding: 20px 0 0;
    border: none;
  }
}

.talents-container {
  max-width: 1110px;
  margin: 0 auto;

  .interested-talents {
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;
    font-size: 23px;
    line-height: 30px;

    @include for-size(phone-only) {
      font-size: 20px;
      line-height: 28px;
      padding: 0 15px;
    }
  }
}

.wrp-candidates-rates {
  max-width: 620px;
  margin: 60px auto 30px;
  padding: 0 15px;

  .candidates-rates {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 30px;
    background-color: #f1f5f5;
    border-radius: 10px;
  }

  .logo {
    margin-right: 30px;

    img {
      display: block;
      width: 50px;
      height: auto;
    }
  }

  .description {
    font-size: 12px;
    line-height: 19px;
    text-align: left;
  }

  @include for-size(phone-only) {
    margin-top: 30px;

    .candidates-rates {
      padding: 10px 20px;
    }

    .logo {
      display: none;
    }
  }
}

@import "../../mixins.scss";

.popup-item {
  cursor: pointer;
}

.popup-container {
  &:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vmax;
    top: 0;
    left: 0;
    z-index: 1104;
  }
  .container {
    position: fixed;
    background-color: #fff;
    z-index: 1105;
    width: 624px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 60px 0 60px;
    border-radius: 20px;
    .content {
      text-align: center;
      margin-bottom: 40px;
      font: 15px/20px $baseFont;
      color: #333;
      a {
        color: $brandColor;
      }
    }
    footer {
      min-height: 20px;
      margin: 0 -60px;
      &.isFetching {
        button {
          opacity: 0.2;
        }
      }
      button {
        background-color: transparent;
        border: 0;
        border-top: 1px solid #ececec;
        border-radius: 0;
        padding: 20px;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        width: 100%;
        &.two {
          width: 50%;
        }
      }
      button:first-child {
        border-right: 1px solid #ececec;
        color: #8e8e8e;
        border-bottom-left-radius: 20px;
      }
      button:last-child {
        color: $brandColor;
        border-bottom-right-radius: 20px;
      }
    }
  }
}
